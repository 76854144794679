

import jQuery from 'jquery';
window.$ =  jQuery;

require('../vendor/jquery.nav.js');
function scrollTo () {

        $('.js-contact').on('click', function(){
            $("html, body").animate({ scrollTop: $('#contact').offset().top - 130}, 1000);
        });

        $('.js-gotop').on('click',function(){
            $('html,body').animate({ scrollTop: 0 });
            return false;
        })

};

export  {scrollTo};