
import $ from 'jquery';

function nav () {

    $('.js-toggle-button').on('click', function (e) {
        e.preventDefault();
        // $('.js-favs').animate({width:'toggle'},480);
        $('.js-nav').toggleClass('is-active');
        var widthbar= window.innerWidth - document.documentElement.clientWidth;

        $('body').toggleClass('c-nav__open');
        /*if($('body').hasClass('c-nav__open')){
            $('body').css('width','calc(100% - '+widthbar+'px)');
        }else{
            $('body').css('width','100%');
        }*/

    });
    $(document).ready(function(){


        if($(window).scrollTop()>150){
            $('.c-header').addClass('stuck');
        }

    });
    $( window ).on( "load", function() {
        $('.js-nav').css("display","block");
        var topheader = $('.c-header__top').outerHeight();
        $('.js-nav').css("top",topheader);

    });

    $('.js-subnav').on('click', function(e){
        e.preventDefault();
        var targetnav=$(this).attr('data-rel');
        $("div").find("[data-subnav='" + targetnav + "']").slideToggle();

    });

    $('.js-search').on('click',function () {
        $('.js-searchblock').fadeToggle();
    });

    $('.has-submenu > a').on('click', function(e){
        e.preventDefault();
        e.stopPropagation();
        $(this).parents('.has-submenu').toggleClass('active');
    });

    $(window).scroll(function(){
        isStuck();
    });
    $(document).ready(function(){
        isStuck();
    });

    function isStuck(){
        var scroll = $(window).scrollTop();
        if(scroll>350){
            if(!$('.c-header').hasClass('stuck')){
               // var originalHeight = $('.c-header').outerHeight();
                $('.c-header').addClass('stuck');
               /* var minHeight = $('.c-header').outerHeight();
                $('.container').css('padding-top', 59);*/

            }
        }else if(scroll<350){
            $('.c-header').removeClass('stuck');
            //$('.container').css('padding-top', 0);

        }
    }

};



export  {nav};