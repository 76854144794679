import $ from 'jquery';
import lightgallery from 'lightgallery';
import  'lg-autoplay';
import  'lg-thumbnail';
import  'lg-fullscreen';
import  'lg-zoom';
import  'lg-pager';


function lgallery () {

    $('.js-lightbox').lightGallery({
        selector: '.js-lightbox-item',
        exThumbImage: 'data-exthumbimage'
    });
};

export  {lgallery};