import $ from 'jquery';
import LazyLoad from "vanilla-lazyload";


function lazy () {


    var lazyLoadInstance = new LazyLoad({
        elements_selector: "img",
        load_delay: 0,
        threshold:500
        // ... more custom settings?
    });

    if (lazyLoadInstance) {
        lazyLoadInstance.update();
    }
};

export  {lazy};