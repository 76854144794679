
//import {mainSlider} from 'modules/slider';
import {nav} from './modules/nav';
import {scrollTo} from './modules/scrollto';
//import {popups} from './modules/popups';
//import {svg} from './modules/svg';
//import {modal} from './modules/modal';
//import {more} from './modules/more';
//import {scrollReveal} from './modules/scrollreveal';
import {lazy} from './modules/lazyload';
import {lgallery} from './modules/lightgallery';
//import {clickOutside} from './modules/clickOutside';
import {videoModal} from './modules/modalVideo';

import AOS from 'aos';


//mainSlider();
nav();
scrollTo();
//modal();
//svg();
//more();
//scrollReveal();
lazy();
lgallery();
//clickOutside();
videoModal();

AOS.init(
    {
        offset: 50
    }
);

